<template>
  <b-container>
    <b-row class="justify-content-center py-4">
      <b-col lg="4">
        <b-card class="shadow-sm">
          <div v-if="isConfirmed">
            <h4 class="text-center mb-3">Email is confirmed!</h4>
            <router-link
              :to="{ name: 'AuthLogin' }"
              class="btn btn-primary w-100"
              >Login Now</router-link
            >
          </div>
          <div v-if="isExpired">
            <h4 class="text-center mb-3">Oops, link expired!</h4>
            <hr />
            <p v-if="resendLinkSuccess" class="text-center">
              <small>A new confirmation link is sent successfully.</small>
            </p>
            <p v-if="error" class="text-danger text-center">
              <small>{{ error }}</small>
            </p>
            <b-button @click="resendLink" variant="primary" block
              >Resend Confirmation Link</b-button
            >
          </div>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import UserService from '@/services/UserService'

  export default {
    name: 'EmailConfirmation',

    data () {
      return {
        isConfirmed: false,
        isExpired: false,
        resendLinkSuccess: false,
        error: ''
      }
    },

    methods: {
      async confirmEmail () {
        // Extract token
        const { token } = this.$route.params
        try {
          // Call api to confirm email
          await UserService.confirmEmail(token)
          // On email confirmation success
          this.isConfirmed = true
        } catch (error) {
          // UserService.confirmEmail fails
          this.isExpired = true
        }
      },

      async resendLink () {
        // Extract userId
        const { userId } = this.$route.params
        try {
          // Call api to resend link
          await UserService.resendConfirmLink(userId)
          // Show resend link success message
          this.error = ''
          this.resendLinkSuccess = true
        } catch (error) {
          console.log(error)
          // Show error
          this.error = error.response.data.message
        }
      }
    },

    created () {
      this.confirmEmail()
    }
  }
</script>

<style></style>
